import { loadView } from './utils'
import { MAIN, SECONDARY } from './page-types'
import { i18n } from '../i18n'

const SETTLEMENTS_MODULE = 'settlements'
const PERMISSION_READ_LIST = 'read_list_settlement'
const PERMISSION_READ = 'read_settlement'

export const settlementsRoot = {
  path: 'settlements',
  component: loadView('ViewSettlements', SETTLEMENTS_MODULE)
}

export const settlementsList = {
  path: '',
  name: 'settlements',
  component: loadView('ViewSettlementsList', SETTLEMENTS_MODULE),
  meta: {
    navigationDrawer: true,
    module: SETTLEMENTS_MODULE,
    role: PERMISSION_READ_LIST,
    pageType: MAIN,
    breadcrumb: () => {
      return {
        text: i18n.t('settlements.title.list')
      }
    }
  }
}

export const settlementsDetails = {
  path: ':settlementId',
  name: 'settlements-details',
  component: loadView('ViewSettlementDetails', SETTLEMENTS_MODULE),
  meta: {
    navigationDrawer: true,
    module: SETTLEMENTS_MODULE,
    role: PERMISSION_READ,
    pageType: SECONDARY,
    breadcrumb: () => {
      return {
        text: i18n.t('settlements.title.details'),
        parent: settlementsList.name
      }
    }
  }
}

export default [
  {
    ...settlementsRoot,
    children: [settlementsList, settlementsDetails]
  }
]
