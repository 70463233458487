import { loadView } from './utils'
import { MAIN, SECONDARY } from './page-types'
import { i18n } from '../i18n'

const MODULE = 'pay-by-link'
const PERMISSION_READ_LIST = 'read_list_pay-by-link'
const PERMISSION_CREATE = 'create_pay-by-link'
const PERMISSION_READ = 'read_pay-by-link'

export const payByLinkRoot = {
  path: 'pay-by-link',
  component: loadView('ViewPayByLink', 'pay-by-link')
}

export const payByLinkList = {
  path: '',
  name: 'pay-by-link',
  component: loadView('ViewPayByLinkList', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    role: PERMISSION_READ_LIST,
    pageType: MAIN,
    breadcrumb: () => {
      return {
        text: i18n.t('pay-by-link.title.list')
      }
    }
  }
}

export const payByLinkCreate = {
  path: 'create',
  name: 'create-pay-by-link',
  component: loadView('ViewPayByLinkCreate', MODULE),
  meta: {
    navigationDrawer: false,
    module: MODULE,
    role: PERMISSION_CREATE,
    pageType: SECONDARY
  }
}

export const payByLinkDetails = {
  path: ':payByLinkId',
  name: 'pay-by-link-details',
  component: loadView('ViewPayByLinkDetails', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    role: PERMISSION_READ,
    pageType: SECONDARY,
    breadcrumb: () => {
      return {
        text: i18n.t('pay-by-link.title.details'),
        parent: payByLinkList.name
      }
    }
  }
}

export default [
  {
    ...payByLinkRoot,
    children: [payByLinkList, payByLinkCreate, payByLinkDetails]
  }
]
