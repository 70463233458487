import { loadView } from './utils'
import { MAIN, SECONDARY } from './page-types'
import router from './index'
import { i18n } from '../i18n'

const MODULE = 'purchases'
const PERMISSION_READ_PURCHASES_LIST = 'read_list_purchases_shop'
const PERMISSION_READ_PURCHASES = 'read_purchases_shop'
const PERMISSION_READ_PURCHASE_REFUNDS_LIST = 'read_list_refunds_purchases_shop'
const PERMISSION_READ_PURCHASE_DISPUTES_LIST =
  'read_list_disputes_purchases_shop'
const PERMISSION_READ_PURCHASE_REFUNDS_DETAILS = 'read_refunds_purchases_shop'
const PERMISSION_CREATE_PURCHASE_REFUNDS = 'create_refunds_purchases_shop'

export const shopRoot = {
  path: 'shops/:entityId',
  name: 'shops',
  component: loadView('ViewShop', MODULE)
}

export const refundsRoot = {
  path: 'refunds',
  component: loadView('ViewPurchases', MODULE)
}

export const purchasesRoot = {
  path: 'purchases',
  component: loadView('ViewPurchases', MODULE)
}

export const refundsList = {
  path: '',
  name: 'refunds',
  component: loadView('ViewRefundsList', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    role: function generateMandatoryShopPermission() {
      return `${PERMISSION_READ_PURCHASE_REFUNDS_LIST}_${router.currentRoute.params.entityId}`
    },
    pageType: MAIN,
    breadcrumb: ({ shopName }) => {
      return {
        text: shopName
      }
    }
  }
}

export const refundsDetails = {
  path: ':refundId',
  name: 'refunds-details',
  component: loadView('ViewRefundsDetails', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    role: function generateMandatoryShopPermission() {
      return `${PERMISSION_READ_PURCHASE_REFUNDS_DETAILS}_${router.currentRoute.params.entityId}`
    },
    pageType: SECONDARY,
    breadcrumb: () => {
      return {
        text: i18n.t('purchases.refund_title.details'),
        parent: refundsList.name
      }
    }
  }
}

export const purchasesList = {
  path: '',
  name: 'purchases',
  component: loadView('ViewPurchasesList', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    role: function generateMandatoryShopPermission() {
      return `${PERMISSION_READ_PURCHASES_LIST}_${router.currentRoute.params.entityId}`
    },
    pageType: MAIN,
    breadcrumb: ({ shopName }) => {
      return {
        text: shopName
      }
    }
  }
}

export const purchasesDetails = {
  path: ':purchaseId',
  name: 'purchases-details',
  component: loadView('ViewPurchasesDetails', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    role: function generateMandatoryShopPermission() {
      return `${PERMISSION_READ_PURCHASES}_${router.currentRoute.params.entityId}`
    },
    pageType: SECONDARY,
    breadcrumb: () => {
      return {
        text: i18n.t('purchases.title.details'),
        parent: purchasesList.name
      }
    }
  }
}

export const purchasesRefundsList = {
  path: ':purchaseId/refunds',
  name: 'purchases-refunds',
  component: loadView('ViewPurchasesRefundsList', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    role: function generateMandatoryShopPermission() {
      return `${PERMISSION_READ_PURCHASE_REFUNDS_LIST}_${router.currentRoute.params.entityId}`
    },
    pageType: SECONDARY,
    breadcrumb: () => {
      return {
        text: i18n.t('purchases.refund_title.list'),
        parent: purchasesDetails.name
      }
    }
  }
}

export const purchasesRefundsCreate = {
  path: ':purchaseId/refunds/create',
  name: 'purchases-refunds-create',
  component: loadView('ViewPurchasesRefundsCreate', MODULE),
  meta: {
    module: MODULE,
    role: function generateMandatoryShopPermission() {
      return `${PERMISSION_CREATE_PURCHASE_REFUNDS}_${router.currentRoute.params.entityId}`
    },
    pageType: SECONDARY
  }
}

export const purchasesRefundsDetails = {
  path: ':purchaseId/refunds/:refundId',
  name: 'purchases-refunds-details',
  component: loadView('ViewPurchasesRefundsDetails', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    role: function generateMandatoryShopPermission() {
      return `${PERMISSION_READ_PURCHASE_REFUNDS_DETAILS}_${router.currentRoute.params.entityId}`
    },
    pageType: SECONDARY,
    breadcrumb: () => {
      return {
        text: i18n.t('purchases.refund_title.details'),
        parent: purchasesRefundsList.name
      }
    }
  }
}

export const purchasesDisputesList = {
  path: ':purchaseId/disputes',
  name: 'purchases-disputes-list',
  component: loadView('ViewPurchasesDisputesList', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    role: function generateMandatoryShopPermission() {
      return `${PERMISSION_READ_PURCHASE_DISPUTES_LIST}_${router.currentRoute.params.entityId}`
    },
    breadcrumb: () => {
      return {
        text: i18n.t('purchases.disputes.title.list'),
        parent: purchasesDetails.name
      }
    }
  }
}

export default [
  {
    ...shopRoot,
    children: [
      {
        ...refundsRoot,
        children: [refundsList, refundsDetails]
      },
      {
        ...purchasesRoot,
        children: [
          purchasesList,
          purchasesDetails,
          purchasesRefundsCreate,
          purchasesRefundsDetails,
          purchasesRefundsList,
          purchasesDisputesList
        ]
      }
    ]
  }
]
