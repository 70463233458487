import Cookies from 'js-cookie'

export function isCookiesAvailable() {
  const test = 'chomp'
  try {
    Cookies.set(test, test)
    Cookies.remove(test)
    return true
  } catch {
    return false
  }
}
