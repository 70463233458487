import { loadView } from './utils'
import { MAIN, SECONDARY, FUNNEL } from './page-types'
import { i18n } from '../i18n'

const ACCOUNTS_FUNDING_MODULE = 'accounts-funding'
const PERMISSION_READ_LIST = 'read_list_accounts-funding'
const PERMISSION_READ = 'read_accounts-funding'
const PERMISSION_CREATE = 'create_accounts-funding'
const PERMISSION_UPDATE = 'update_accounts-funding'

export const accountsFundingRoot = {
  path: 'accounts-funding',
  component: loadView('ViewAccountsFunding', ACCOUNTS_FUNDING_MODULE)
}

export const accountsFundingList = {
  path: '',
  name: 'accounts-funding',
  component: loadView('ViewAccountsFundingList', ACCOUNTS_FUNDING_MODULE),
  meta: {
    navigationDrawer: true,
    module: ACCOUNTS_FUNDING_MODULE,
    role: PERMISSION_READ_LIST,
    pageType: MAIN,
    breadcrumb: () => {
      return {
        text: i18n.t('accounts-funding.title.list')
      }
    }
  }
}

export const accountsFundingCreate = {
  path: 'new',
  name: 'accounts-funding-add',
  component: loadView('ViewAccountsFundingCreate', ACCOUNTS_FUNDING_MODULE),
  meta: {
    navigationDrawer: true,
    module: ACCOUNTS_FUNDING_MODULE,
    role: PERMISSION_CREATE,
    pageType: SECONDARY
  }
}

export const accountsFundingDetails = {
  path: ':sourceWalletId/transfers/:transferId',
  name: 'accounts-funding-view',
  component: loadView('ViewAccountsFundingDetails', ACCOUNTS_FUNDING_MODULE),
  meta: {
    navigationDrawer: true,
    module: ACCOUNTS_FUNDING_MODULE,
    role: PERMISSION_READ,
    pageType: FUNNEL,
    breadcrumb: () => {
      return {
        text: i18n.t('accounts-funding.title.view'),
        parent: accountsFundingList.name
      }
    }
  }
}

export const accountsFundingEdit = {
  path: ':sourceWalletId/transfers/:transferId/edit',
  name: 'accounts-funding-edit',
  component: loadView('ViewAccountsFundingEdit', ACCOUNTS_FUNDING_MODULE),
  meta: {
    navigationDrawer: true,
    module: ACCOUNTS_FUNDING_MODULE,
    role: PERMISSION_UPDATE,
    pageType: SECONDARY
  }
}

export default [
  {
    ...accountsFundingRoot,
    children: [
      accountsFundingList,
      accountsFundingDetails,
      accountsFundingCreate,
      accountsFundingEdit
    ]
  }
]
