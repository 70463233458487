import {
  mdiPollBoxOutline,
  mdiWalletOutline,
  mdiAccountMultiple,
  mdiFormatListBulleted,
  mdiWalletGiftcard,
  mdiCreditCardOutline,
  mdiSwapHorizontal,
  mdiArrowCollapse,
  mdiCogOutline,
  mdiBasketOutline,
  mdiLink,
  mdiAccountCircle,
  mdiFlag
} from '@mdi/js'

export default {
  users: {
    icon: mdiAccountMultiple,
    routeName: 'users'
  },
  roles: {
    icon: mdiFormatListBulleted,
    routeName: 'roles'
  },
  'top-ups': {
    icon: mdiWalletGiftcard,
    routeName: 'top-ups'
  },
  'b2c-credits': {
    icon: mdiWalletGiftcard,
    routeName: 'b2c-credits',
    'b2c-credits-wallet': {
      routeName: 'b2c-credits-wallet'
    }
  },
  balance: {
    icon: mdiPollBoxOutline,
    routeName: 'balance'
  },
  'payment-methods': {
    icon: mdiCreditCardOutline,
    routeName: 'payment-methods'
  },
  'wallet-accounts': {
    icon: mdiWalletOutline,
    routeName: 'wallet-accounts'
  },
  'accounts-funding': {
    icon: mdiSwapHorizontal,
    routeName: 'accounts-funding'
  },
  settlement: {
    icon: mdiArrowCollapse,
    routeName: 'settlements'
  },
  'account-details': {
    icon: mdiAccountCircle,
    routeName: 'account-details'
  },
  disputes: {
    icon: mdiFlag,
    routeName: 'disputes'
  },
  'shop-configurations': {
    icon: mdiCogOutline,
    routeName: 'shop-configurations'
  },
  shop: {
    icon: mdiBasketOutline,
    propertyToGroupIds: 'shops',
    routeName: 'shops',
    purchases: {
      useModuleId: true,
      routeName: 'purchases'
    },
    refunds: {
      useModuleId: true,
      routeName: 'refunds'
    }
  },
  'pay-by-link': {
    icon: mdiLink,
    routeName: 'pay-by-link'
  }
}
