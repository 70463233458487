/* istanbul ignore file */

import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App'
import router from './router/index.js'
import store from './store'
import './register-service-worker'
import { i18n } from './i18n'
import '@/fonts.css'
import { getEnv } from '@JumiaPayAIG/js-utils/env'
import notificationPlugin from './plugins/notification'
import VueClipboard from 'vue-clipboard2'
import Cookies from 'js-cookie'

Cookies.defaults = {
  domain: window.location.host.replace(/.*?\./, '')
}

Vue.prototype.$appName = getEnv('VUE_APP_NAME')
Vue.prototype.$companyName = getEnv('VUE_APP_COMPANY_NAME')
Vue.prototype.$companyLegalName = getEnv('VUE_APP_COMPANY_LEGAL_NAME')

Vue.config.productionTip = false

Vue.use(VueClipboard)

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

Vue.use(notificationPlugin)
new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
