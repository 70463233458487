import { loadView } from './utils'
import { MAIN, SECONDARY } from './page-types'

const ROLES_MODULE = 'roles'
const PERMISSION_READ = 'read_roles'
const PERMISSION_READ_LIST = 'read_list_roles'
const PERMISSION_UPDATE = 'update_roles'
const PERMISSION_CREATE = 'create_roles'

export default [
  {
    path: 'roles',
    component: loadView('ViewRole', 'roles'),
    children: [
      {
        path: '',
        name: 'roles',
        component: loadView('ViewRoleList', 'roles'),
        meta: {
          navigationDrawer: true,
          module: ROLES_MODULE,
          role: PERMISSION_READ_LIST,
          pageType: MAIN
        }
      },
      {
        path: 'new',
        name: 'role-add',
        component: loadView('ViewRoleCreate', 'roles'),
        meta: {
          module: ROLES_MODULE,
          role: PERMISSION_CREATE,
          pageType: SECONDARY
        }
      },
      {
        path: ':roleId',
        name: 'role-view',
        component: loadView('ViewRoleDetails', 'roles'),
        meta: {
          module: ROLES_MODULE,
          role: PERMISSION_READ,
          pageType: SECONDARY
        }
      },
      {
        path: ':roleId/edit',
        name: 'role-edit',
        component: loadView('ViewRoleEdit', 'roles'),
        meta: {
          module: ROLES_MODULE,
          role: PERMISSION_UPDATE,
          pageType: SECONDARY
        }
      }
    ]
  }
]
