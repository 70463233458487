export const localStorageKeys = {
  authToken: 'auth_token',
  refreshToken: 'refresh_token',
  email: 'email',
  isEmployee: 'is_employee',
  isSupport: 'is_support',
  currencyCode: 'currency_code',
  currencySymbol: 'currency_symbol',
  currencyDecimalPlaces: 'currency_decimal_places',
  callingCode: 'calling_code',
  lang: 'lang'
}

export const cookiesKeys = {
  authToken: 'auth_token',
  authNToken: 'authn_token',
  refreshToken: 'refresh_token',
  idToken: 'id_token'
}
