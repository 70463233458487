import {
  apiLogin,
  apiGetFeatures,
  apiConfigurations,
  apiGetBusinessAreas
} from '../../api/authentication'
import { apiGetCMSBlock } from '../../api'
import { localStorageKeys } from '@/constants'
import { signOutAsExternalUser, signOutAsEmployee } from '@/utils/auth'
import { scrollTop } from '@JumiaPayAIG/js-utils/scroll'
import { getEnv } from '@JumiaPayAIG/js-utils/env'

function loadingPageStart({ commit }) {
  commit('LOADING_PAGE_START')
}

function loadingPageEnd({ commit }) {
  commit('LOADING_PAGE_END')
}

function permissionsToExclude(permissions) {
  if (getEnv('VUE_APP_MODULES_TO_EXCLUDE')) {
    const excludeModules = getEnv('VUE_APP_MODULES_TO_EXCLUDE')
      .split(',')
      .map((phrase) => phrase.trim())

    permissions = permissions.filter((permission) => {
      // Check if any of the phrases in excludeModules are a substring of the permission
      return !excludeModules.some((phrase) => permission.includes(phrase))
    })
  }
  return permissions
}

function localStorageHasConfigurations() {
  const currencyCode = localStorage.getItem(localStorageKeys.currencyCode)
  const currencySymbol = localStorage.getItem(localStorageKeys.currencySymbol)
  const currencyDecimalPlaces = localStorage.getItem(
    localStorageKeys.currencyDecimalPlaces
  )
  const callingCode = localStorage.getItem(localStorageKeys.callingCode)

  return currencyCode && currencySymbol && currencyDecimalPlaces && callingCode
}

function localStorageSetConfigurations({
  currencyCode,
  currencySymbol,
  currencyDecimalPlaces,
  callingCode
}) {
  localStorage.setItem(localStorageKeys.currencyCode, currencyCode)
  localStorage.setItem(localStorageKeys.currencySymbol, currencySymbol)
  localStorage.setItem(
    localStorageKeys.currencyDecimalPlaces,
    currencyDecimalPlaces
  )
  localStorage.setItem(localStorageKeys.callingCode, callingCode)
}

async function login({ commit }, { authCode, userType } = {}) {
  if (!localStorageHasConfigurations()) {
    const configurations = await apiConfigurations()
    localStorageSetConfigurations(configurations)
  }

  commit('CONFIGS_UPDATED', {
    currencyCode: localStorage.getItem(localStorageKeys.currencyCode),
    currencySymbol: localStorage.getItem(localStorageKeys.currencySymbol),
    currencyDecimalPlaces: localStorage.getItem(
      localStorageKeys.currencyDecimalPlaces
    ),
    callingCode: localStorage.getItem(localStorageKeys.callingCode)
  })

  const { email, areas, isEmployee, isSupport } = await apiLogin({
    authCode,
    userType
  })

  commit('USER_UPDATED', { email, isEmployee, isSupport })
  commit('AREAS_UPDATED', areas)
}

async function getFeatures({ state, commit }, businessAreaId) {
  commit('MENU_LOADING')

  try {
    const {
      isLocked,
      menu,
      businessAreaName,
      permissions,
      groupedIdsByModule
    } = await apiGetFeatures(businessAreaId)

    const businessAreaRole = state.areas.find((el) => el.id === businessAreaId)

    const finalPermissions = permissionsToExclude(permissions)

    commit('MENU_UPDATED', {
      businessAreaId,
      businessAreaSelected: {
        id: businessAreaId,
        name: businessAreaName,
        description: businessAreaRole ? businessAreaRole.description : ''
      },
      isReadOnly: isLocked,
      menu,
      permissions: finalPermissions
    })
    commit('SET_GROUPED_IDS', groupedIdsByModule)

    return {
      menu,
      permissions: finalPermissions
    }
  } catch (error) {
    commit('MENU_LOADING')
    throw error
  }
}

async function getBusinessAreas({ commit }) {
  try {
    const businessAreas = await apiGetBusinessAreas()
    commit('AREAS_UPDATED', businessAreas)
  } catch {}
}

async function searchBusinessAreas({ commit }, { searchString } = {}) {
  try {
    const businessAreas = await apiGetBusinessAreas({ searchString })
    commit('FILTERED_AREAS_UPDATED', businessAreas)
  } catch {}
}

async function getCMSBlock({ commit }, key) {
  const cmsBlock = await apiGetCMSBlock({ key })
  commit('CMS_ADDED', { key, cmsBlock })
}

// Notifications

function showSuccessNotification({ commit }, { action, module, message }) {
  commit('SNACKBAR_UPDATED', { module, action, type: 'success', message })
  scrollTop()
}

function showErrorNotification({ commit }, { message }) {
  commit('SNACKBAR_UPDATED', { type: 'error', message })
  scrollTop()
}

function cleanSnackbar({ commit }) {
  commit('SNACKBAR_CLEANED')
}

// Permissions
async function updatePermissions({ commit }) {
  await login({ commit })
  await getFeatures({ commit }, localStorage.getItem('selected_area'))
}

function signOut({ state }) {
  if (state.isEmployee) {
    signOutAsEmployee()
  } else {
    signOutAsExternalUser()
  }
}

export default {
  loadingPageStart,
  loadingPageEnd,

  login,
  signOut,

  getBusinessAreas,
  searchBusinessAreas,

  getFeatures,
  getCMSBlock,

  cleanSnackbar,
  showSuccessNotification,
  showErrorNotification,

  updatePermissions
}
