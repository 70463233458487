import Cookies from 'js-cookie'
import {
  warioAxios,
  toadAxios,
  setAxiosXAuthToken,
  setAxiosXBusinessArea
} from './axios-configurations'
import { businessAreasMap } from './response-mapper'
import featureMapper from '../constants/menu'
import { localStorageKeys, cookiesKeys } from '@/constants'
import { mdiHome } from '@mdi/js'
import { i18n } from '../i18n'
import { getEnv } from '@JumiaPayAIG/js-utils/env'
import axios from 'axios'

const CancelToken = axios.CancelToken
let cancel

const endpoint = {
  login: 'login',
  refreshToken: 'refresh-token',
  features: 'features/business-area',
  businessAreas: 'business-areas',
  configurations: 'configurations'
}

async function apiConfigurations() {
  const response = await warioAxios.get(endpoint.configurations)

  const data = response.data

  const {
    currency: {
      code: currencyCode,
      symbol: currencySymbol,
      decimal_places: currencyDecimalPlaces
    } = {},
    country: { calling_code: callingCode } = {}
  } = data

  return {
    currencyCode,
    currencySymbol,
    currencyDecimalPlaces,
    callingCode
  }
}

async function apiLogin({ authCode, userType } = {}) {
  let instance = warioAxios
  let payload = { context: 'business-area', auth_code: authCode }

  if (
    getEnv('VUE_APP_KEYCLOAK_LOGIN') &&
    getEnv('VUE_APP_KEYCLOAK_LOGIN').toLowerCase() === 'true'
  ) {
    instance = toadAxios

    const isEmployee = localStorage.getItem(localStorageKeys.isEmployee)
    const userTypeParam = userType ? '?user_type=' + userType : ''
    payload = {
      ...payload,
      redirect_uri:
        window.location.origin + window.location.pathname + userTypeParam,
      white_label: getEnv('VUE_APP_COMPANY_NAME'),
      user_type: userType || (isEmployee === 'true' ? 'internal' : 'external')
    }
  }

  const { data } = await instance.post(endpoint.login, payload)

  const businessAreas = businessAreasMap(data.business_area)
  const {
    email,
    token,
    refresh_token: refreshToken,
    is_jumia: isEmployee = false,
    is_general_support: isSupport = false,
    authn_token: authNToken = null,
    id_token: idToken = null
  } = data

  Cookies.set(cookiesKeys.refreshToken, refreshToken)
  Cookies.set(cookiesKeys.authToken, token)
  Cookies.set(cookiesKeys.authNToken, authNToken)
  Cookies.set(cookiesKeys.idToken, idToken)

  localStorage.setItem(localStorageKeys.email, email)
  localStorage.setItem(localStorageKeys.isEmployee, isEmployee)
  localStorage.setItem(localStorageKeys.isSupport, isSupport)

  setAxiosXAuthToken(token, authNToken)

  return {
    email,
    areas: businessAreas,
    isEmployee,
    isSupport
  }
}

async function apiGetFeatures(areaId) {
  setAxiosXBusinessArea(areaId)

  const groupedIdsByModule = {}
  const response = await warioAxios.get(endpoint.features)
  const {
    is_locked: isLocked,
    business_area: { name: businessAreaName } = {},
    modules
  } = response.data

  const menu = []
  let modulesToExclude = []

  if (getEnv('VUE_APP_MODULES_TO_EXCLUDE')) {
    modulesToExclude = getEnv('VUE_APP_MODULES_TO_EXCLUDE')
      .split(',')
      .map((phrase) => phrase.trim())
  }

  if (getEnv('VUE_APP_GOOGLE_FORM')) {
    menu.push({
      icon: mdiHome,
      id: 'get-started',
      code: 'get-started',
      text: i18n.t('get_started'),
      to: {
        name: 'get-started'
      }
    })
  }

  let permissions = []

  for (const module of modules) {
    menu.push({
      id: module.id,
      text: module.name,
      isGroup: true
    })

    for (const feature of module.features) {
      if (feature.subfeatures) {
        const expandableModule = {
          text: feature.name,
          icon: featureMapper[feature.code]?.icon,
          modules: [],
          code: feature.code,
          to: {
            name: featureMapper[feature.code]?.routeName,
            params: {
              entityId: feature.id
            }
          }
        }

        for (const subfeature of feature.subfeatures) {
          const {
            [feature.code]: {
              [subfeature.code]: { useModuleId, routeName } = {}
            } = {}
          } = featureMapper

          if (routeName) {
            expandableModule.modules.push({
              text: subfeature.name,
              to: {
                name: routeName,
                params: {
                  entityId: useModuleId ? feature.id : subfeature.id
                }
              },
              code: subfeature.code
            })

            const propertyToGroupIds =
              featureMapper[feature.code]?.propertyToGroupIds
            if (propertyToGroupIds) {
              groupedIdsByModule[propertyToGroupIds] = {
                ...groupedIdsByModule[propertyToGroupIds],
                [feature.id]: feature.name
              }
            }

            permissions = permissions.concat(subfeature.permissions)
          }
        }

        menu.push(expandableModule)
      } else if (
        featureMapper[feature.code] &&
        !modulesToExclude.includes(feature.code)
      ) {
        menu.push({
          id: feature.id,
          text: feature.name,
          icon: featureMapper[feature.code].icon,
          to: {
            name: featureMapper[feature.code].routeName
          },
          code: feature.code
        })

        permissions = permissions.concat(feature.permissions)
      }
    }
  }

  return {
    isLocked,
    businessAreaName,
    menu,
    permissions,
    groupedIdsByModule
  }
}

async function apiGetBusinessAreas({ searchString } = {}) {
  if (cancel) {
    cancel()
  }

  if (typeof searchString === 'string' && searchString.length < 3) {
    return null
  }

  const response = await warioAxios.get(endpoint.businessAreas, {
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c
    }),
    params: {
      q: searchString,
      limit:
        localStorage.getItem(localStorageKeys.isSupport) === 'true'
          ? '30'
          : undefined
    }
  })

  cancel = null

  return businessAreasMap(response.data).sort((a, b) =>
    a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  )
}

export { apiLogin, apiGetFeatures, apiConfigurations, apiGetBusinessAreas }
