import { loadView } from './utils'
import { MAIN, SECONDARY } from './page-types'

const PAYMENT_METHODS_MODULE = 'payment-methods'
const PERMISSION_READ_LIST = 'read_list_payment-methods'
const PERMISSION_CREATE = 'create_payment-methods'

export default [
  {
    path: 'payment-methods',
    component: loadView('ViewPaymentMethod', 'payment-methods'),
    children: [
      {
        path: '',
        name: 'payment-methods',
        component: loadView('ViewPaymentInstrumentsList', 'payment-methods'),
        meta: {
          navigationDrawer: true,
          module: PAYMENT_METHODS_MODULE,
          role: PERMISSION_READ_LIST,
          pageType: MAIN
        }
      },
      {
        path: 'new',
        name: 'payment-methods-add',
        component: loadView('ViewPaymentMethodCreate', 'payment-methods'),
        meta: {
          navigationDrawer: false,
          module: PAYMENT_METHODS_MODULE,
          role: PERMISSION_CREATE,
          pageType: SECONDARY
        }
      }
    ]
  }
]
