import { loadView } from './utils'
import { i18n } from '../i18n'
import { MAIN, SECONDARY } from './page-types'

const WALLET_ACCOUNTS_MODULE = 'wallet-accounts'

export const walletAccountsRoot = {
  path: 'wallet-accounts',
  component: loadView('ViewWalletAccounts', WALLET_ACCOUNTS_MODULE)
}

export const walletAccountsList = {
  path: '',
  name: 'wallet-accounts',
  component: loadView('ViewWalletAccountsList', WALLET_ACCOUNTS_MODULE),
  meta: {
    navigationDrawer: true,
    module: WALLET_ACCOUNTS_MODULE,
    role: 'read_list_wallet-accounts',
    pageType: MAIN,
    breadcrumb: () => {
      return {
        text: i18n.t('wallet-accounts.title.list')
      }
    }
  }
}

export const walletAccountsDetails = {
  path: ':walletId',
  name: 'wallet-accounts-details',
  component: loadView('ViewWalletAccountsDetails', WALLET_ACCOUNTS_MODULE),
  meta: {
    navigationDrawer: true,
    module: WALLET_ACCOUNTS_MODULE,
    role: 'read_details_wallet-accounts',
    pageType: MAIN,
    breadcrumb: ({ details }) => {
      return {
        text: details,
        parent: walletAccountsList.name
      }
    }
  }
}

export const walletAccountsReportsList = {
  path: ':walletId/reports',
  name: 'wallet-accounts-reports',
  component: loadView('ViewWalletAccountsReportsList', WALLET_ACCOUNTS_MODULE),
  meta: {
    navigationDrawer: true,
    module: WALLET_ACCOUNTS_MODULE,
    role: 'read_list_report_wallet-accounts',
    pageType: MAIN,
    breadcrumb: () => {
      return {
        text: i18n.t('wallet-accounts.report_title.list'),
        parent: walletAccountsDetails.name
      }
    }
  }
}

export const walletAccountsReportsCreate = {
  path: ':walletId/create-report',
  name: 'wallet-accounts-reports-add',
  component: loadView(
    'ViewWalletAccountsReportsCreate',
    WALLET_ACCOUNTS_MODULE
  ),
  meta: {
    navigationDrawer: false,
    module: WALLET_ACCOUNTS_MODULE,
    role: 'create_report_wallet-accounts',
    pageType: SECONDARY
  }
}

export default [
  {
    ...walletAccountsRoot,
    children: [
      walletAccountsList,
      walletAccountsDetails,
      walletAccountsReportsList,
      walletAccountsReportsCreate
    ]
  }
]
