function push(obj) {
  window.dataLayer && window.dataLayer.push(obj)
}

function pushPageContext(type, path, layout) {
  push({
    page: {
      type,
      path,
      layout
    }
  })
}

function pushUserContext(id, jumiaId) {
  push({
    user: {
      id,
      jumiaId
    }
  })
}

function pushEvent(category, subCategory, action, label, value, event) {
  push({
    eventContent: {
      category,
      subCategory,
      action,
      label,
      value
    },
    event
  })
}

function pushAppEvent(category, subCategory, action, label) {
  pushEvent(category, subCategory, action, label, 0, 'jpayb2b')
}

function pushErrorEvent(category, subCategory, action, label) {
  pushEvent(category, subCategory, action, label, 0, 'error')
}

function pushScreenOpenedEvent(name, path) {
  pushAppEvent('Screen', name, 'Opened', path)
}

function pushButtonClickedEvent(name, path) {
  pushAppEvent('Button', name, 'Clicked', path)
}

function pushLinkClickedEvent(name, path) {
  pushAppEvent('Link', name, 'Clicked', path)
}

function pushCardClickedEvent(name, path) {
  pushAppEvent('Card', name, 'Clicked', path)
}

function pushFilterAppliedEvent(name, path) {
  pushAppEvent('Filter', name, 'Applied', path)
}

function pushAppErrorEvent(action, label) {
  pushErrorEvent('error', 'application', action, label)
}

function pushApiErrorEvent(action, label) {
  pushErrorEvent('error', 'api', action, label)
}

export {
  pushEvent,
  pushAppEvent,
  pushErrorEvent,
  pushScreenOpenedEvent,
  pushButtonClickedEvent,
  pushLinkClickedEvent,
  pushCardClickedEvent,
  pushFilterAppliedEvent,
  pushPageContext,
  pushUserContext,
  pushAppErrorEvent,
  pushApiErrorEvent
}
