import { loadView } from './utils'
import { MAIN } from './page-types'

const SHOP_CONFIGURATIONS_MODULE = 'shop-configurations'
const PERMISSION_READ = 'read_list_shop-configurations'

export default [
  {
    path: 'shop-configurations',
    name: 'shop-configurations',
    component: loadView(
      'ViewShopConfigurationsList',
      SHOP_CONFIGURATIONS_MODULE
    ),
    meta: {
      navigationDrawer: true,
      module: SHOP_CONFIGURATIONS_MODULE,
      role: PERMISSION_READ,
      pageType: MAIN
    }
  }
]
