export function isLocalStorageAvailable() {
  const test = 'chomp'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch {
    return false
  }
}
