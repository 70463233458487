import { mdiDotsHorizontal } from '@mdi/js'
import { i18n } from '@/i18n'

function globalAlert(state) {
  if (state.globalAlertMessage) {
    return {
      message: state.globalAlertMessage
    }
  }
}

function areasToAdministrate(state) {
  if (state.areas) {
    return state.areas.filter((el) => el.description === 'ADMIN')
  }
  return []
}

function genericError() {
  return {
    title: i18n.t('error.title'),
    description: i18n.t('error.description')
  }
}

function businessAreas(state) {
  if (state.areasFiltered) {
    return state.areasFiltered
  } else {
    if (state.isSupport) {
      return []
    } else {
      return state.areas
    }
  }
}

function userHasAreasToAdministrate(state) {
  return areasToAdministrate(state).length > 0
}

function availablePagesOnMenu(state) {
  if (state.menu) {
    let menu = state.menu.filter((el) => !el.isGroup)
    menu = menu.slice(0, 2)
    menu.push({
      text: 'More',
      icon: mdiDotsHorizontal,
      to: { hash: '#menu' }
    })

    return menu
  }
  return null
}

function cmsBlock(state) {
  return function ({ key }) {
    return state.cmsBlock[key]
  }
}

// Permissions

function hasPermission(state) {
  return function (permission) {
    if (!permission) return true
    return state.permissions.some(function findPermission(el) {
      return el === permission
    })
  }
}

function can(state) {
  return function ({ action, module }) {
    return state.permissions.some(function findPermission(el) {
      return el === `${action}_${module}`
    })
  }
}

function canSee(state) {
  return function hasReadPermission(module) {
    return can(state)({ action: 'read', module })
  }
}

function canCreate(state) {
  return function hasCreatePermission(module) {
    return can(state)({ action: 'create', module })
  }
}

function canEdit(state) {
  return function hasUpdatePermission(module) {
    return can(state)({ action: 'update', module })
  }
}

function canDelete(state) {
  return function hasDeletePermission(module) {
    return can(state)({ action: 'delete', module })
  }
}

function canDownload(state) {
  return function hasDownloadPermission(module) {
    return can(state)({ action: 'download', module })
  }
}

export default {
  genericError,

  globalAlert,
  areasToAdministrate,
  userHasAreasToAdministrate,
  businessAreas,

  availablePagesOnMenu,

  cmsBlock,

  hasPermission,
  can,
  canSee,
  canCreate,
  canEdit,
  canDelete,
  canDownload
}
