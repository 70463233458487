import { i18n } from '@/i18n'
import { mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import { getEnv } from '@JumiaPayAIG/js-utils/env'

function SET_GROUPED_IDS(state, groupedIdsByModule) {
  for (const data in groupedIdsByModule) {
    state[data] = groupedIdsByModule[data]
  }
}

function LOADING_PAGE_START(state) {
  state.loadingPage = true
}

function LOADING_PAGE_END(state) {
  state.loadingPage = false
}

function USER_UPDATED(state, { email, isEmployee, isSupport }) {
  state.email = email
  state.isEmployee = isEmployee
  state.isSupport = isSupport
}

function CONFIGS_UPDATED(
  state,
  { currencyCode, currencySymbol, currencyDecimalPlaces, callingCode }
) {
  state.currencyCode = currencyCode
  state.currencySymbol = currencySymbol
  state.currencyDecimalPlaces = currencyDecimalPlaces
  state.callingCode = callingCode
}

function AREAS_UPDATED(state, areas) {
  state.areas = areas
}

function FILTERED_AREAS_UPDATED(state, areas) {
  state.areasFiltered = areas
}

function MENU_LOADING(state) {
  state.menuLoading = !state.menuLoading
}

function MENU_UPDATED(
  state,
  { businessAreaId, businessAreaSelected, isReadOnly, menu, permissions }
) {
  state.menu = menu
  state.businessAreaId = businessAreaId
  state.businessAreaSelected = businessAreaSelected
  state.isReadOnly = isReadOnly
  if (isReadOnly) {
    state.globalAlertMessage = i18n.t('business_account.hint.locked', {
      appName: getEnv('VUE_APP_COMPANY_NAME')
    })
  } else {
    state.globalAlertMessage = null
  }
  state.permissions = permissions
  state.menuLoading = false
}

function SNACKBAR_UPDATED(state, { type, action, module, message }) {
  if (type) {
    state.snackbar.type = type
    state.snackbar.icon = type === 'success' ? mdiCheck : mdiAlertCircleOutline
    state.snackbar.textColor = type === 'success' ? '#417505' : '#b81a0a'
    state.snackbar.backgroundColor = type === 'success' ? '#d9e3cd' : '#f1d1ce'

    if (message) {
      state.snackbar.messages = Array.isArray(message) ? message : [message]
    } else if (action && module) {
      state.snackbar.messages = [
        i18n.t(`success_message.${action}`, {
          entity: i18n.t(`${module}.entity`)
        })
      ]
    }
  }
}

function SNACKBAR_CLEANED(state) {
  state.snackbar.type = undefined
  state.snackbar.icon = undefined
  state.snackbar.textColor = undefined
  state.snackbar.backgroundColor = undefined
  state.snackbar.messages = undefined
}

function CMS_ADDED(state, { key, cmsBlock }) {
  state.cmsBlock = {
    ...state.cmsBlock,
    [key]: cmsBlock
  }
}

export default {
  LOADING_PAGE_START,
  LOADING_PAGE_END,
  USER_UPDATED,
  CONFIGS_UPDATED,
  AREAS_UPDATED,
  FILTERED_AREAS_UPDATED,
  MENU_LOADING,
  MENU_UPDATED,
  SNACKBAR_UPDATED,
  SNACKBAR_CLEANED,
  CMS_ADDED,
  SET_GROUPED_IDS
}
