function listFiltersMap(filters) {
  const mappedFilters = filters.map((el) => {
    const { code, name, options, type } = el

    const mappedOptions = options.map((option) => {
      const { code, name } = option

      return {
        code,
        label: name
      }
    })
    return {
      code,
      label: name,
      options: mappedOptions,
      type
    }
  })

  return mappedFilters
}

function businessAreasMap(list) {
  return list.map((el) => businessAreaMap(el))
}

function businessAreaMap(item) {
  const { id, name, role: { name: roleName } = {} } = item

  return {
    id,
    name,
    description: roleName
  }
}

export { listFiltersMap, businessAreasMap }
