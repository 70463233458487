import { loadView } from './utils'
import { MAIN, FUNNEL, SECONDARY } from './page-types'

const TOP_UPS_MODULE = 'top-ups'
const PERMISSION_READ = 'read_top-ups'
const PERMISSION_LIST_READ = 'read_list_top-ups'
const PERMISSION_READ_TRANSACTIONS_LIST = 'read_list_transactions_top-ups'
const PERMISSION_UPDATE = 'update_top-ups'
const PERMISSION_CREATE = 'create_top-ups'

export default [
  {
    path: 'top-ups',
    component: loadView('ViewTopUp', 'top-ups'),
    children: [
      {
        path: '',
        name: 'top-ups',
        component: loadView('ViewTopUpList', 'top-ups'),
        meta: {
          navigationDrawer: true,
          module: TOP_UPS_MODULE,
          role: PERMISSION_LIST_READ,
          pageType: MAIN
        }
      },
      {
        path: 'new',
        name: 'top-up-add',
        component: loadView('ViewTopUpCreate', 'top-ups'),
        meta: {
          module: TOP_UPS_MODULE,
          role: PERMISSION_CREATE,
          pageType: SECONDARY
        }
      },
      {
        path: ':topUpId',
        name: 'top-up-view',
        component: loadView('ViewTopUpDetails', 'top-ups'),
        meta: {
          navigationDrawer: true,
          module: TOP_UPS_MODULE,
          role: PERMISSION_READ,
          pageType: FUNNEL
        }
      },
      {
        path: ':topUpId/edit',
        name: 'top-up-edit',
        component: loadView('ViewTopUpEdit', 'top-ups'),
        meta: {
          module: TOP_UPS_MODULE,
          role: PERMISSION_UPDATE,
          pageType: SECONDARY
        }
      },
      {
        path: ':topUpId/transactions',
        name: 'top-up-transactions',
        component: loadView('ViewTopUpTransactionsList', 'top-ups'),
        meta: {
          navigationDrawer: true,
          module: TOP_UPS_MODULE,
          role: PERMISSION_READ_TRANSACTIONS_LIST,
          pageType: FUNNEL
        }
      }
    ]
  }
]
