<template>
  <v-dialog v-model="addToHomeScreenDialog" max-width="290">
    <v-card>
      <div class="px-4 pt-4 text-h6">
        {{ $t('add_to_homescreen.title') }}
      </div>
      <div class="description pa-4">
        {{ $t('add_to_homescreen.description') }}
      </div>
      <div class="text-right">
        <v-btn text color="primary" @click="onDecline">
          {{ $t('no') }}
        </v-btn>
        <v-btn text color="primary" @click="onConfirm">
          {{ $t('yes') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookies from 'js-cookie'
import { mapState } from 'vuex'
export default {
  name: 'AppAddToHomeScreen',
  data() {
    return {
      addToHomeScreenDialog: false,
      deferredPrompt: null
    }
  },
  computed: {
    ...mapState('app', {
      email: (state) => state.email
    }),
    showAddToHomeDialog() {
      return this.deferredPrompt && this.email
    }
  },
  watch: {
    showAddToHomeDialog: function (val) {
      if (val) {
        this.addToHomeScreenDialog = true
      }
    }
  },
  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault()
      // Stash the event so it can be triggered later.
      if (Cookies.get('add-to-home') !== 'false') {
        this.deferredPrompt = e
      }
    })
  },
  methods: {
    onDecline() {
      this.addToHomeScreenDialog = false
      Cookies.set('add-to-home', 'false', { expires: 7 })
      // this.$gtm.buttonClicked('add-home', 'no')
      this.deferredPrompt = null
    },
    async onConfirm() {
      // this.$gtm.buttonClicked('add-home', 'yes')
      this.addToHomeScreenDialog = false
      this.deferredPrompt.prompt()
      // Wait for the user to respond to the prompt
      // var choiceResult = await this.deferredPrompt.userChoice
      // if (choiceResult.outcome === 'accepted') {
      //   this.$gtm.buttonClicked('google-add-home', 'yes')
      // } else {
      //   this.$gtm.buttonClicked('google-add-home', 'no')
      // }
      this.deferredPrompt = null
    }
  }
}
</script>
