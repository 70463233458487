import { nabbitAxios } from './axios-configurations'

const endpoint = {
  cmsBlock: (key) => `block/get/key/${key}`
}

async function apiGetCMSBlock({ key }) {
  const response = await nabbitAxios.get(endpoint.cmsBlock(key))
  const { payload: { content } = {} } = response.data
  return content
}

export { apiGetCMSBlock }
