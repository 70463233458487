const NotificationPlugin = {
  install(Vue) {
    Vue.prototype.$showErrorNotification = function (error) {
      const { response: { status, data: { message } = {} } = {} } = error

      if (status === 403) {
        this.$store.dispatch('app/showErrorNotification', {
          message: this.$t('no_permissions.perform_action')
        })
      } else if (message) {
        this.$store.dispatch('app/showErrorNotification', {
          message
        })
      } else {
        this.$store.dispatch('app/showErrorNotification', {
          message: this.$t('error.notification')
        })
        throw error
      }
    }

    Vue.prototype.$showSuccessNotification = function (options) {
      this.$store.dispatch('app/showSuccessNotification', options)
    }
  }
}

export default NotificationPlugin
