import { loadView } from './utils'
import { MAIN, SECONDARY } from './page-types'
import router from './index'
import { i18n } from '../i18n'

const MODULE = 'b2c-credits'
const PERMISSION_READ_B2C_CREDITS_WALLET_DETAILS =
  'read_single-credits_b2c-credits'
const PERMISSION_CREATE_SINGLE_CREDIT = 'create_single-credits_b2c-credits'
const PERMISSION_READ_SINGLE_CREDIT_LIST =
  'read_list_single-credits_b2c-credits'
const PERMISSION_READ_SINGLE_CREDIT = 'read_single-credits_b2c-credits'
const PERMISSION_EDIT_SINGLE_CREDIT = 'update_single-credits_b2c-credits'
const PERMISSION_CREATE_BULK_CREDIT = 'create_bulk-credits_b2c-credits'
const PERMISSION_READ_BULK_CREDIT = 'read_bulk-credits_b2c-credits'
const PERMISSION_READ_BULK_CREDIT_LIST = 'read_list_bulk-credits_b2c-credits'
const PERMISSION_READ_BULK_CREDIT_ITEM_LIST =
  'read_list_bulk-credits-details_b2c-credits'
const PERMISSION_EDIT_BULK_CREDIT = 'update_bulk-credits_b2c-credits'

export const b2cCreditsRoot = {
  path: 'b2c-credits',
  component: loadView('ViewB2CCredits', MODULE)
}

export const b2cCreditsWalletsEmpty = {
  path: '',
  name: 'b2c-credits',
  component: loadView('ViewB2CCreditsWalletsEmpty', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    pageType: MAIN,
    breadcrumb: () => {
      return {
        text: i18n.t('b2c-credits.title.list')
      }
    }
  }
}

export const b2cCreditsWalletDetails = {
  path: 'wallets/:entityId',
  name: 'b2c-credits-wallet',
  component: loadView('ViewB2CCreditsWalletDetails', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    role: function generateMandatoryCreditsWalletPermission() {
      return `${PERMISSION_READ_B2C_CREDITS_WALLET_DETAILS}_${router.currentRoute.params.entityId}`
    },
    pageType: MAIN,
    breadcrumb: ({ walletDetails }) => {
      return {
        text: walletDetails
      }
    }
  }
}

export const b2cCreditsCreate = {
  path: 'wallets/:entityId/single-credits/create',
  name: 'b2c-credits-single-credit-create',
  component: loadView('ViewB2CCreditsSingleCreditsCreate', MODULE),
  meta: {
    navigationDrawer: false,
    module: MODULE,
    role: function generateMandatoryCreditsWalletPermission() {
      return `${PERMISSION_CREATE_SINGLE_CREDIT}_${router.currentRoute.params.entityId}`
    },
    pageType: SECONDARY
  }
}

export const b2cCreditsEdit = {
  path: 'wallets/:entityId/single-credits/:singleCreditId/edit',
  name: 'b2c-credits-single-credit-edit',
  component: loadView('ViewB2CCreditsSingleCreditEdit', MODULE),
  meta: {
    navigationDrawer: false,
    module: MODULE,
    role: function generateMandatoryCreditsWalletPermission() {
      return `${PERMISSION_EDIT_SINGLE_CREDIT}_${router.currentRoute.params.entityId}`
    },
    pageType: SECONDARY
  }
}

export const b2cCreditsList = {
  path: 'wallets/:entityId/single-credits',
  name: 'b2c-credits-single-credits',
  component: loadView('ViewB2CCreditsSingleCreditsList', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    role: function generateMandatoryCreditsWalletPermission() {
      return `${PERMISSION_READ_SINGLE_CREDIT_LIST}_${router.currentRoute.params.entityId}`
    },
    pageType: MAIN,
    breadcrumb: () => {
      return {
        text: i18n.t('b2c-credits.title.single_credits_list'),
        parent: b2cCreditsWalletDetails.name
      }
    }
  }
}

export const b2cCreditsDetails = {
  path: 'wallets/:entityId/single-credits/:singleCreditId',
  name: 'b2c-credits-single-credit',
  component: loadView('ViewB2CCreditsSingleCreditDetails', MODULE),
  meta: {
    navigationDrawer: false,
    module: MODULE,
    role: function generateMandatoryCreditsWalletPermission() {
      return `${PERMISSION_READ_SINGLE_CREDIT}_${router.currentRoute.params.entityId}`
    },
    pageType: MAIN,
    breadcrumb: ({ singleCreditDetails }) => {
      return {
        text: singleCreditDetails,
        parent: b2cCreditsWalletDetails.name
      }
    }
  }
}

export const b2cBulkCreditsCreate = {
  path: 'wallets/:entityId/bulk-credits/create',
  name: 'b2c-credits-bulk-credit-create',
  component: loadView('ViewB2CCreditsBulkCreditsCreate', MODULE),
  meta: {
    navigationDrawer: false,
    module: MODULE,
    role: function generateMandatoryCreditsWalletPermission() {
      return `${PERMISSION_CREATE_BULK_CREDIT}_${router.currentRoute.params.entityId}`
    },
    pageType: SECONDARY
  }
}

export const b2cBulkCreditsEdit = {
  path: 'wallets/:entityId/bulk-credits/:bulkCreditId/edit',
  name: 'b2c-credits-bulk-credit-edit',
  component: loadView('ViewB2CCreditsBulkCreditEdit', MODULE),
  meta: {
    navigationDrawer: false,
    module: MODULE,
    role: function generateMandatoryCreditsWalletPermission() {
      return `${PERMISSION_EDIT_BULK_CREDIT}_${router.currentRoute.params.entityId}`
    },
    pageType: SECONDARY
  }
}

export const b2cBulkCreditsList = {
  path: 'wallets/:entityId/bulk-credits',
  name: 'b2c-credits-bulk-credits',
  component: loadView('ViewB2CCreditsBulkCreditsList', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    role: function generateMandatoryCreditsWalletPermission() {
      return `${PERMISSION_READ_BULK_CREDIT_LIST}_${router.currentRoute.params.entityId}`
    },
    pageType: MAIN,
    breadcrumb: () => {
      return {
        text: i18n.t('b2c-credits.title.bulk_credits_list'),
        parent: b2cCreditsWalletDetails.name
      }
    }
  }
}

export const b2cBulkCreditsDetails = {
  path: 'wallets/:entityId/bulk-credits/:bulkCreditId',
  name: 'b2c-credits-bulk-credit',
  component: loadView('ViewB2CCreditsBulkCreditDetails', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    role: function generateMandatoryCreditsWalletPermission() {
      return `${PERMISSION_READ_BULK_CREDIT}_${router.currentRoute.params.entityId}`
    },
    pageType: MAIN,
    breadcrumb: ({ bulkCreditDetails }) => {
      return {
        text: bulkCreditDetails,
        parent: b2cCreditsWalletDetails.name
      }
    }
  }
}

export const b2cBulkCreditItemsList = {
  path: 'wallets/:entityId/bulk-credits/:bulkCreditId/credits',
  name: 'b2c-credits-bulk-credit-items',
  component: loadView('ViewB2CCreditsBulkCreditItemsList', MODULE),
  meta: {
    navigationDrawer: true,
    module: MODULE,
    role: function generateMandatoryCreditsWalletPermission() {
      return `${PERMISSION_READ_BULK_CREDIT_ITEM_LIST}_${router.currentRoute.params.entityId}`
    },
    pageType: MAIN,
    breadcrumb: () => {
      return {
        text: i18n.t('b2c-credits.title.bulk_credit_items_list'),
        parent: b2cBulkCreditsDetails.name
      }
    }
  }
}

export default [
  {
    ...b2cCreditsRoot,
    children: [
      b2cCreditsWalletsEmpty,
      b2cCreditsWalletDetails,
      b2cCreditsCreate,
      b2cCreditsEdit,
      b2cCreditsList,
      b2cCreditsDetails,
      b2cBulkCreditsCreate,
      b2cBulkCreditsEdit,
      b2cBulkCreditsList,
      b2cBulkCreditsDetails,
      b2cBulkCreditItemsList
    ]
  }
]
