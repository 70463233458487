import Cookies from 'js-cookie'
import { getEnv } from '@JumiaPayAIG/js-utils/env'
import { localStorageKeys, cookiesKeys } from '@/constants'
import { i18n, defaultLang } from '../i18n'

export function clearConfigurations() {
  localStorage.removeItem(localStorageKeys.currencyCode)
  localStorage.removeItem(localStorageKeys.currencySymbol)
  localStorage.removeItem(localStorageKeys.currencyDecimalPlaces)
  localStorage.removeItem(localStorageKeys.callingCode)
}

export function clearAuthTokens() {
  Cookies.remove(cookiesKeys.authToken)
  Cookies.remove(cookiesKeys.authNToken)
  Cookies.remove(cookiesKeys.refreshToken)
  Cookies.remove(cookiesKeys.idToken)

  localStorage.removeItem(localStorageKeys.email)
  localStorage.removeItem(localStorageKeys.isEmployee)
  localStorage.removeItem(localStorageKeys.isSupport)
}

function signInAsExternalUser(url) {
  clearConfigurations()

  const lang = i18n.locale
  const country = lang === 'en' ? '-GB' : lang === 'fr' ? '-FR' : ''

  let returnUrl = url

  if (!url) {
    returnUrl = window.location.origin + window.location.pathname

    if (window.location.search) {
      returnUrl += window.location.search
    }
  }

  if (i18n.locale !== defaultLang) {
    if (window.location.search) {
      returnUrl += encodeURIComponent('&lang=') + i18n.locale
    } else {
      returnUrl += encodeURIComponent('?lang=') + i18n.locale
    }
  }

  if (
    getEnv('VUE_APP_KEYCLOAK_LOGIN') &&
    getEnv('VUE_APP_KEYCLOAK_LOGIN').toLowerCase() === 'true'
  ) {
    window.location.href = `${getEnv(
      'VUE_APP_NEW_AUTH_URL'
    )}/auth?client_id=${getEnv(
      'VUE_APP_CLIENT_ID'
    )}&response_mode=query&response_type=code&scope=openid&redirect_uri=${returnUrl}?user_type=external`
  } else {
    window.location.href = `${getEnv(
      'VUE_APP_AUTH_URL'
    )}?lang=${lang}${country}&redirect_url=${returnUrl}&force_hard_session=1`
  }
}

function signInAsEmployee(url) {
  clearConfigurations()

  let returnUrl = url

  if (!url) {
    returnUrl = window.location.origin + window.location.pathname

    if (window.location.search) {
      returnUrl += window.location.search
    }
  }

  if (i18n.locale !== defaultLang) {
    if (window.location.search) {
      returnUrl += encodeURIComponent('&lang=') + i18n.locale
    } else {
      returnUrl += encodeURIComponent('?lang=') + i18n.locale
    }
  }

  if (
    getEnv('VUE_APP_KEYCLOAK_LOGIN') &&
    getEnv('VUE_APP_KEYCLOAK_LOGIN').toLowerCase() === 'true'
  ) {
    window.location.href = `${getEnv(
      'VUE_APP_ACL_URL'
    )}login?redirect=${returnUrl}%3Fuser_type%3Dinternal&application=PAY_BUSINESS_AREA&aclFlow=v2`
  } else {
    window.location.href = `${getEnv(
      'VUE_APP_ACL_URL'
    )}login?redirect=${returnUrl}&application=PAY_BUSINESS_AREA&aclFlow=v2`
  }
}

function signOutAsExternalUser(url) {
  const idToken = Cookies.get(cookiesKeys.idToken)
  clearAuthTokens()
  clearConfigurations()

  const lang = i18n.locale
  const country = lang === 'en' ? '-GB' : lang === 'fr' ? '-FR' : ''

  let returnUrl =
    url ||
    (getEnv('VUE_APP_FROSTY_URL')
      ? getEnv('VUE_APP_FROSTY_URL')
      : window.location.origin)

  if (i18n.locale !== defaultLang) {
    if (getEnv('VUE_APP_FROSTY_URL') && !url) {
      returnUrl += `${i18n.locale}/`
    } else {
      returnUrl = `${returnUrl}${encodeURIComponent('?lang=')}${i18n.locale}`
    }
  }

  if (
    getEnv('VUE_APP_KEYCLOAK_LOGIN') &&
    getEnv('VUE_APP_KEYCLOAK_LOGIN').toLowerCase() === 'true'
  ) {
    window.location.href = `${getEnv(
      'VUE_APP_NEW_AUTH_URL'
    )}/logout?scope=openid&id_token_hint=${idToken}&post_logout_redirect_uri=${returnUrl}`
  } else {
    window.location.href = `${getEnv(
      'VUE_APP_AUTH_URL'
    )}sign-out?lang=${lang}${country}&redirect_url=${returnUrl}`
  }
}

function signOutAsEmployee() {
  clearAuthTokens()
  clearConfigurations()

  let returnUrl = getEnv('VUE_APP_FROSTY_URL')
    ? getEnv('VUE_APP_FROSTY_URL')
    : window.location.origin

  if (i18n.locale !== defaultLang) {
    if (getEnv('VUE_APP_FROSTY_URL')) {
      returnUrl += `${i18n.locale}/`
    } else {
      returnUrl = `${returnUrl}${encodeURIComponent('?lang=')}${i18n.locale}`
    }
  }

  window.location.href = `${getEnv(
    'VUE_APP_ACL_URL'
  )}login?redirect=${returnUrl}&application=PAY_BUSINESS_AREA&aclFlow=v2&logout=true&redirectAfterLogout=true`
}

export {
  signInAsExternalUser,
  signOutAsExternalUser,
  signInAsEmployee,
  signOutAsEmployee
}
