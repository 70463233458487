import { loadView } from './utils'
import { MAIN } from './page-types'
import store from '@/store'

const ACCOUNT_MODULE = 'account'

export default [
  {
    path: '/account',
    component: loadView('ViewAccount', 'account'),
    children: [
      {
        path: 'connect',
        name: 'account-connect',
        component: loadView('ViewAccountConnect', 'account'),
        meta: {
          module: ACCOUNT_MODULE,
          store: false,
          pageType: MAIN
        },
        beforeEnter: (to, from, next) => {
          if (!store.getters['app/userHasAreasToAdministrate']) {
            next({ name: 'account-create', query: to.query })
          }
          next()
        }
      },
      {
        path: 'create',
        name: 'account-create',
        component: loadView('ViewAccountCreate', 'account'),
        meta: {
          module: ACCOUNT_MODULE,
          store: false,
          pageType: MAIN
        }
      }
    ]
  }
]
