import { loadView } from './utils'
import { MAIN, SECONDARY } from './page-types'
import { i18n } from '../i18n'

const BALANCE_MODULE = 'balance'
const PERMISSION_READ_LIST = 'read_list_transactions_balance'
const PERMISSION_READ_TRANSACTION_DETAILS = 'read_transactions_balance'
const PERMISSION_WITHDRAW = 'create_withdraw_balance'

export const balanceRoot = {
  path: 'balance',
  component: loadView('ViewBalance', BALANCE_MODULE)
}

export const balanceList = {
  path: '',
  name: 'balance',
  component: loadView('ViewBalanceTransactionsList', BALANCE_MODULE),
  meta: {
    navigationDrawer: true,
    module: BALANCE_MODULE,
    role: PERMISSION_READ_LIST,
    pageType: MAIN,
    breadcrumb: () => {
      return {
        text: i18n.t('balance.title.list')
      }
    }
  }
}

export const balanceWithdraw = {
  path: 'withdraw',
  name: 'balance-withdraw',
  component: loadView('ViewBalanceWithdraw', BALANCE_MODULE),
  meta: {
    navigationDrawer: false,
    module: BALANCE_MODULE,
    role: PERMISSION_WITHDRAW,
    pageType: SECONDARY
  }
}

export const balanceDetails = {
  path: ':balanceId',
  name: 'balance-transactions-details',
  component: loadView('ViewBalanceTransactionDetails', BALANCE_MODULE),
  meta: {
    navigationDrawer: true,
    module: BALANCE_MODULE,
    role: PERMISSION_READ_TRANSACTION_DETAILS,
    pageType: SECONDARY,
    breadcrumb: () => {
      return {
        text: i18n.t('balance.title.details'),
        parent: balanceList.name
      }
    }
  }
}

export default [
  {
    ...balanceRoot,
    children: [balanceList, balanceWithdraw, balanceDetails]
  }
]
