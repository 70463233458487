import { loadView } from './utils'
import { MAIN, SECONDARY } from './page-types'
import { i18n } from '../i18n'

const PURCHASES_MODULE = 'purchases'
const PERMISSION_READ_LIST = 'read_list_disputes'
const PERMISSION_READ = 'read_disputes'
const PERMISSION_UPLOAD = 'upload_documents_disputes'

export const disputesRoot = {
  path: 'disputes',
  component: loadView('ViewDisputes', PURCHASES_MODULE)
}

export const disputesList = {
  path: '',
  name: 'disputes',
  component: loadView('ViewDisputesList', PURCHASES_MODULE),
  meta: {
    navigationDrawer: true,
    module: PURCHASES_MODULE,
    role: PERMISSION_READ_LIST,
    pageType: MAIN,
    breadcrumb: () => {
      return {
        text: i18n.t('purchases.disputes.title.list')
      }
    }
  }
}

export const disputeDocuments = {
  path: 'disputes-documents',
  name: 'disputes-documents',
  component: loadView('ViewDisputesDocument', PURCHASES_MODULE),
  meta: {
    navigationDrawer: false,
    module: PURCHASES_MODULE,
    role: PERMISSION_UPLOAD,
    pageType: SECONDARY
  }
}

export const disputesDetails = {
  path: ':disputeId',
  name: 'disputes-details',
  component: loadView('ViewDisputesDetails', PURCHASES_MODULE),
  meta: {
    navigationDrawer: true,
    module: PURCHASES_MODULE,
    role: PERMISSION_READ,
    pageType: MAIN,
    breadcrumb: () => {
      return {
        text: i18n.t('purchases.disputes.title.details'),
        parent: disputesList.name
      }
    }
  }
}

export const disputeUpload = {
  path: ':disputeId/upload',
  name: 'dispute-upload',
  component: loadView('ViewDisputeUpload', PURCHASES_MODULE),
  meta: {
    navigationDrawer: false,
    module: PURCHASES_MODULE,
    role: PERMISSION_UPLOAD,
    pageType: SECONDARY
  }
}

export default [
  {
    ...disputesRoot,
    children: [disputesList, disputeDocuments, disputesDetails, disputeUpload]
  }
]
