/* istanbul ignore file */

import Cookies from 'js-cookie'
import axios from 'axios'
import store from '../store'
import { i18n } from '../i18n'
import {
  isForbidden,
  isUnauthorized,
  hasAttachment
} from '@JumiaPayAIG/js-utils/errors'
import { downloadFile } from '@JumiaPayAIG/js-utils/file'
import {
  warioAxios,
  toadAxios,
  setAxiosXAuthToken
} from './axios-configurations'
import { cookiesKeys, localStorageKeys } from '@/constants'
import { getEnv } from '@JumiaPayAIG/js-utils/env'

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

async function errorHandler(error) {
  const originalRequest = error.config

  if (error && error.response && error.response.status) {
    const response = error.response

    if (error.config.url.includes('refresh-token') || originalRequest._retry) {
      await store.dispatch('app/signOut')
      return Promise.reject(error)
    }

    if (isForbidden(error)) {
      const { response: { data: { code: errorCode } = {} } = {} } = error

      // When user loose permissions to the endpoint
      // update his permissions
      // 12 -> loose permissions to the endpoint
      // 13 -> loose permissions to the Busines Area
      if (
        !error.config.url.includes('login') &&
        !error.config.url.includes('refresh-token') &&
        !error.config.url.includes('features/business-area')
      ) {
        if (errorCode === 13) {
          store.dispatch('app/showErrorNotification', {
            message: i18n.t('no_permissions.perform_action')
          })
          await store.dispatch('app/signOut')
        } else {
          try {
            await store.dispatch('app/updatePermissions')
          } catch (error) {
            await store.dispatch('app/signOut')
          }
        }
      }
      throw error
    }

    if (isUnauthorized(error) && !error.config.url.includes('login')) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
          .then((newToken) => {
            originalRequest.headers['X-Auth-Token'] = newToken
            return axios(originalRequest)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      }

      originalRequest._retry = true
      isRefreshing = true

      const token = Cookies.get(cookiesKeys.authToken)
      const refreshToken = Cookies.get(cookiesKeys.refreshToken)
      const isEmployee = localStorage.getItem(localStorageKeys.isEmployee)

      let instance = warioAxios
      let payload = {
        token,
        refresh_token: refreshToken,
        context: 'business-area'
      }

      return new Promise(function (resolve, reject) {
        if (
          getEnv('VUE_APP_KEYCLOAK_LOGIN') &&
          getEnv('VUE_APP_KEYCLOAK_LOGIN').toLowerCase() === 'true'
        ) {
          instance = toadAxios
          payload = {
            auth_token: token,
            refresh_token: refreshToken,
            context: 'business-area',
            user_type: isEmployee === 'true' ? 'internal' : 'external'
          }
        }
        instance
          .post('/refresh-token', payload)
          .then((response) => {
            const {
              token: newToken,
              refresh_token: newRefreshToken,
              auth_token: authToken,
              authn_token: authNToken
            } = response.data

            // update tokens in cookies
            Cookies.set(cookiesKeys.authToken, newToken)
            Cookies.set(cookiesKeys.refreshToken, newRefreshToken)

            if (
              getEnv('VUE_APP_KEYCLOAK_LOGIN') &&
              getEnv('VUE_APP_KEYCLOAK_LOGIN').toLowerCase() === 'true'
            ) {
              originalRequest.headers.Authorization = `Bearer ${authToken}`
              originalRequest.headers['X-AuthN-Token'] = authNToken
            } else {
              originalRequest.headers['X-Auth-Token'] = newToken
            }

            setAxiosXAuthToken(newToken, authNToken)

            processQueue(null, newToken)
            resolve(axios(originalRequest))
          })
          .catch((err) => {
            processQueue(err, null)
            reject(err)
          })
          .finally(() => {
            isRefreshing = false
          })
      })
    }

    if (hasAttachment(response)) {
      downloadFile(response)
    }
  }

  return Promise.reject(error)
}

export { errorHandler }
