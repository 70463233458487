import { loadView } from './utils'
import { MAIN, SECONDARY } from './page-types'

const ACCOUNTS_DETAILS_MODULE = 'account'
const PERMISSION_READ = 'read_account-details'
const PRMISSIONS_UPDATE = 'update_account-details'

export default [
  {
    path: 'account-details',
    name: 'account-details',
    component: loadView('ViewAccountDetails', 'account'),
    meta: {
      navigationDrawer: true,
      module: ACCOUNTS_DETAILS_MODULE,
      role: PERMISSION_READ,
      pageType: MAIN
    }
  },
  {
    path: 'account-edit',
    name: 'account-edit',
    component: loadView('ViewAccountEdit', 'account'),
    meta: {
      module: ACCOUNTS_DETAILS_MODULE,
      role: PRMISSIONS_UPDATE,
      pageType: SECONDARY
    }
  }
]
